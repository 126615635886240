import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icon from 'components/icons/Icons'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import Loader from 'react-loader-spinner'

interface Props {
  iconName: IconName
  text: string
  colorIcon?: string
  colorText?: string
  size?: number
  onClick?: () => void
  loading?: boolean
  disabled?: boolean
}

const LabelWithIcon = ({
  text,
  iconName,
  colorIcon,
  colorText = '#000',
  size = 20,
  onClick,
  loading = false,
  disabled = false,
}: Props) => {
  const [Theme] = useTheme()
  const isEnabled = !disabled && !loading

  return (
    <MainContainer onClick={isEnabled ? onClick : undefined} isClickable={!!onClick && isEnabled}>
      <Icon name={iconName} size={size} color={isEnabled ? colorIcon : Theme.colors.middleGrey} />
      <Label color={colorText} loading={loading}>
        {text}
      </Label>
      {loading && (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} height={20} width={20} />
        </LoaderContainer>
      )}
    </MainContainer>
  )
}
export default LabelWithIcon

const MainContainer = styled.div<{ isClickable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) => props.isClickable && 'cursor: pointer;&:hover{text-decoration: underline;}'}
`
const Label = styled.p<{ color: string; loading: boolean }>`
  ${(props) => props.theme.fonts.label}
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.color};
  margin: 0px 0px 0px 5px;
  opacity: ${(props) => (props.loading ? 0 : 1)};
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`
const LoaderContainer = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 4px;
`
