import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { showedDate } from 'news/utils'
import { format, addDays } from 'date-fns'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import Carousel from 'components/image/Carousel'
import LabelWithIcon from 'components/label/LabelWithIcon'
import Modal from 'components/modal/Modal'
import ModalContent from 'components/modal/ValidationContent'
import NewsImage from 'news/NewsImage'
import ZoomImage from 'components/image/ZoomImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'

import * as store from '../store'
import api from '../api'
import { executeGraphRequest } from 'authent365/utils/utils'
import useNavigation from 'utils/navigation/useNavigation'
import utils from 'utils/strings'

import YammerImage from 'news/img/yammer.png'

import Loader from 'react-loader-spinner'
import { formatDistanceToNow } from 'date-fns'

interface Props {
  id: string
}

const NewsDetails = ({ id }: Props) => {
  const [Theme] = useTheme()
  const navigation = useNavigation()
  const [article, setArticle] = React.useState<NewsDetailV6>()
  const [status, setStatus] = React.useState<'loading' | 'error' | 'ok'>('loading')
  const [photoURLs, setPhotoURLs] = React.useState<CarouselItem[]>([])
  const [calendarLoading, setCalendarLoading] = React.useState(false)

  const i18n = useI18n()
  const liked = useReducer(store.store, (s) => s.liked)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const isLiked = liked.indexOf(id) >= 0
  const [nbLikes, setNbLikes] = React.useState(0)
  const user = useReducer(userStore.store, (s) => s.user)

  React.useEffect(() => {
    load()
    if (site && user) {
      api.article(id, site.id, user.type, 'en').then((res) => {
        analytics.track({
          screenName: analyticsKeys.newsDetails,
          userType: user.type,
          currentSite: site,
          mySites,
          label: res.title,
        })
      })
    }
  }, [i18n.lang])

  const load = (displayLoading?: boolean) => {
    if (displayLoading) {
      setStatus('loading')
    }
    if (site && user) {
      api
        .article(id, site.id, user.type)
        .then((res) => {
          if (res) {
            setArticle(res)
            setPhotoURLs(
              res.photos
                .filter((p) => !!p.url)
                .sort((p1, p2) => p1.position - p2.position)
                .map((p) => ({ url: p.url, key: `KEY_${p.position}`, description: p.description }))
            )
            setNbLikes(res.nbLikes)
            setStatus('ok')
          } else {
            setStatus('error')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  const toggleLike = () => {
    if (site && user) {
      const action =
        liked.indexOf(id) < 0
          ? api.like(id, site.id, user.type).then(() => store.actions.setLiked([...liked, id]))
          : api.unlike(id, site.id, user.type).then(() => store.actions.setLiked(liked.filter((l) => l !== id)))

      action.then(() => load()).catch(Logger.error)
    }
  }

  const addToCalendar = () => {
    setCalendarLoading(true)
    if (article && user && site) {
      if (!article.startDate || !article.endDate) {
        return null
      }

      const startDate = `${format(new Date(article.startDate), 'yyyy-MM-dd')}T${
        (!article.entireDay && article.startTime) || '00:00:00'
      }`
      const endDate = `${format(
        !!article.entireDay ? addDays(new Date(article.endDate), 1) : new Date(article.endDate),
        'yyyy-MM-dd'
      )}T${(!article.entireDay && article.endTime) || '00:00:00'}`

      const event = {
        start: {
          dateTime: startDate,
          timeZone: site.timezone,
        },
        end: {
          dateTime: endDate,
          timeZone: site.timezone,
        },
        isAllDay: !!article.entireDay,
        subject: article.title,
        location: {
          displayName: article.location || '',
        },
      }

      executeGraphRequest(user.type, '/me/events', 'POST', event)
        .then(() =>
          Modal.open(() => (
            <ModalContent
              title={i18n.t(`screens.news.calendarSucceed`)}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        )
        .catch(() =>
          Modal.open(() => (
            <ModalContent
              title={i18n.t(`screens.news.calendarFailed`)}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        )
        .finally(() => setCalendarLoading(false))
    }
  }

  const openInYammer = () => (article ? window.open(article.webUrl) : undefined)

  const openPDF = (pdf: NewsPDFV6) => {
    if (!!pdf.associatedButtonName && !!pdf.url) {
      if (pdf.url.endsWith('.pdf')) {
        navigation.push('/pdf', {
          pdfUrl: pdf.url,
          pdfTitle: pdf.associatedButtonName,
        })
      } else {
        window.open(`/${i18n.lang}/${pdf.url}`)
      }
    }
    return null
  }

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error' || !article) {
    return (
      <CenteredView>
        <NoNewsError>{i18n.t('screens.news.detail.error')}</NoNewsError>
        <ErrorButtonContainer>
          <Button label={i18n.t('common.retry')} onClick={() => load(true)} font={Theme.fonts.h3Bold} />
        </ErrorButtonContainer>
      </CenteredView>
    )
  }

  const displayedDate =
    article.type === 'INFO'
      ? i18n.t('screens.news.publicationDate', { date: new Date(article.publicationDate) })
      : !!article.startDate && !!article.endDate
      ? showedDate(i18n, new Date(article.startDate), new Date(article.endDate))
      : undefined

  return (
    <>
      <TitleHelmet title={article.title || i18n.t('screens.news.title')} />
      <MainContainer>
        <Breadcrumb screen="news" path="news" details={article.title} navigation={navigation} />
        <CenteredView>
          <InfoBar>
            <InfoContainer>
              {article.startDate && article.endDate && (
                <TopLabelContainer>
                  <LabelWithIcon
                    text={displayedDate || ''}
                    iconName="agenda"
                    colorIcon={Theme.colors.blue}
                    colorText={Theme.colors.middleGrey}
                  />
                </TopLabelContainer>
              )}
              {article.startTime && article.endTime && (
                <TopLabelContainer>
                  <LabelWithIcon
                    text={i18n.t('screens.news.timeEvent', {
                      start: article.startTime.replace(':', 'h').replace('h00', 'h'),
                      end: article.endTime.replace(':', 'h').replace('h00', 'h'),
                    })}
                    iconName="clock"
                    colorIcon={Theme.colors.blue}
                    colorText={Theme.colors.middleGrey}
                  />
                </TopLabelContainer>
              )}
              {article.location && (
                <TopLabelContainer>
                  <LabelWithIcon
                    text={article.location}
                    iconName="pin"
                    colorIcon={Theme.colors.blue}
                    colorText={Theme.colors.middleGrey}
                  />
                </TopLabelContainer>
              )}
            </InfoContainer>
            <LikeContainer>
              {(article.lifecycle === 'DRAFT' || article.lifecycle === 'APPROVED') && (
                <DraftContainer>
                  <Icons name="pencil" color={Theme.colors.blue} size={25} />
                  <InfoText>{i18n.t(`screens.news.${article.lifecycle}`)}</InfoText>
                </DraftContainer>
              )}
              <Icons name="heart-filled" color={Theme.colors.blue} size={25} />
              <InfoText>{i18n.t('screens.news.likes', { count: nbLikes })}</InfoText>
            </LikeContainer>
          </InfoBar>
          <ArticleTitle>{article.title}</ArticleTitle>
          {photoURLs.length > 0 ? (
            <CarouselContainer>
              <Carousel items={photoURLs} height={365} />
            </CarouselContainer>
          ) : (
            <EmptyPhotoContainer>
              <NewsImage
                onClick={() => {
                  if (site) {
                    ZoomImage.open('')
                  }
                }}
                height={365}
                width={590}
              />
            </EmptyPhotoContainer>
          )}
          {article.type === 'EVENT' && !!article.startDate && !!article.endDate && (
            <ArticleDate>{showedDate(i18n, new Date(article.startDate), new Date(article.endDate))}</ArticleDate>
          )}
          {article.type === 'INFO' && article.lifecycle !== 'DRAFT' && (
            <ArticleDate>
              {article.lifecycle === 'APPROVED'
                ? i18n.t('screens.news.scheduledDate', {
                    date: new Date(article.publicationDate),
                  })
                : utils.capitalize(
                    i18n.t('screens.news.publicationDate', {
                      duration: formatDistanceToNow(new Date(article.publicationDate), {
                        locale: i18n.locale,
                      }),
                    })
                  )}
            </ArticleDate>
          )}
          <InfoBar>
            {article.type === 'EVENT' && (
              <BottomLabelContainer
                onKeyDown={(e) => onEnterKey(e, addToCalendar)}
                aria-label={i18n.t('label.ariaLabel.news.saveInCalendar')}>
                <LabelWithIcon
                  text={i18n.t('screens.news.save')}
                  onClick={addToCalendar}
                  iconName="agenda"
                  colorIcon={Theme.colors.blue}
                  colorText={Theme.colors.iconGrey}
                  loading={calendarLoading}
                />
              </BottomLabelContainer>
            )}
            {article.tag === 'YAMMER' && (
              <YammerLabelContainer onClick={openInYammer}>
                <YammerIcon src={YammerImage} alt="" />
                <Label>{i18n.t('screens.news.seeYammer')}</Label>
              </YammerLabelContainer>
            )}
            <BottomLabelContainer
              onKeyDown={(e) => onEnterKey(e, toggleLike)}
              aria-label={i18n.t(`label.ariaLabel.news.${isLiked ? 'notLike' : 'like'}Article`)}>
              <LabelWithIcon
                text={i18n.t(`screens.news.${isLiked ? 'doNotLike' : 'like'}`)}
                onClick={toggleLike}
                iconName={isLiked ? 'heart-crossed' : 'heart'}
                colorIcon={Theme.colors.blue}
                colorText={Theme.colors.iconGrey}
              />
            </BottomLabelContainer>
            {article.pdfs && article.pdfs.length > 0 && (
              <>
                {article.pdfs
                  .filter((pdf) => !!pdf.associatedButtonName && !!pdf.url)
                  .map((pdf) => (
                    <BottomLabelContainer
                      key={pdf.url}
                      onKeyDown={(e) => onEnterKey(e, () => openPDF(pdf))}
                      aria-label={pdf.associatedButtonName}>
                      <LabelWithIcon
                        text={pdf.associatedButtonName as string}
                        onClick={() => openPDF(pdf)}
                        iconName="info"
                        colorIcon={Theme.colors.blue}
                        colorText={Theme.colors.iconGrey}
                      />
                    </BottomLabelContainer>
                  ))}
              </>
            )}
          </InfoBar>

          {article.catchphrase && article.tag !== 'YAMMER' && (
            <Catchphrase dangerouslySetInnerHTML={{ __html: article.catchphrase }} tabIndex={0} />
          )}
          {article.content && (
            <Content
              dangerouslySetInnerHTML={{ __html: article.content }}
              tabIndex={0}
              onClick={(evt) => {
                const target = evt.target as HTMLAnchorElement
                const url = target.href
                if (!!url) {
                  externalLinkTracking(url, analyticsKeys.newsDetails, user, site, mySites)
                }
              }}
            />
          )}
        </CenteredView>
      </MainContainer>
    </>
  )
}

export default NewsDetails

// GENERAL

const MainContainer = styled('div')`
  margin-right: 4%;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 20px;
  }
`
const CenteredView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 33px;
  color: ${(props) => props.theme.colors.middleGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 10px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const EmptyPhotoContainer = styled('div')`
  width: 590px;
  height: 365px;
  background-color: ${(props) => props.theme.colors.middleGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 500px;
    height: 310px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    width: 300px;
    height: 186px;
  }
`
const ErrorButtonContainer = styled('div')`
  display: flex;
  width: 300px;
`

// INFO

const ArticleTitle = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  font-size: 30px;
  line-height: 31px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-top: 12px;
  }
`
const InfoBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 20px;
`
const NoNewsError = styled.p`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
`
const LikeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  margin-bottom: 4px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-right: 20px;
    margin-top: 8px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 15px 0px 4px 8px;
  }
`
const DraftContainer = styled('div')`
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const InfoContainer = styled('div')`
  display: flex;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    flex-direction: column;
  }
`
const InfoText = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0px 5px;
  color: ${(props) => props.theme.colors.middleGrey};
`
const Content = styled.div`
  ${(props) => props.theme.fonts.body};
  margin-top: 30px;
  text-align: justify;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 80px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 0px;
  }
`
const Catchphrase = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0;
  margin-top: 30px;
`
const CarouselContainer = styled('div')``
const TopLabelContainer = styled('div')`
  margin: 14px 0px;
`
const BottomLabelContainer = styled('button')`
  padding-right: 5px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const YammerLabelContainer = styled('button')`
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 2px;
  &:hover {
    text-decoration: underline;
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const Label = styled('path')`
  ${(props) => props.theme.fonts.labelBold}
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.iconGrey};
  margin-left: 5px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`
const YammerIcon = styled('img')`
  width: 20px;
  height: 20px;
`
const ArticleDate = styled.p`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.turquoise};
  margin: 15px 0px;
`
