import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { showedDate } from 'news/utils'
import analytics, { analyticsKeys } from 'utils/analytics'
import utils from 'utils/strings'

import Button from 'components/button/Button'
import FilterButton from 'components/button/Shortcut'
import Modal from 'components/modal/Modal'
import ModalContent from './ModalContent'
import YammerImage from 'news/img/yammer.png'
import NewsImage from 'news/NewsImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Icons from 'components/icons/Icons'

import api from '../api'
import * as store from '../store'

import Loader from 'react-loader-spinner'
import { formatDistanceToNow } from 'date-fns'

interface Props {
  limit?: number
  isHomePage?: boolean
  navigate?: () => void
}

const ALL_TAGS = ['CAMPUS', 'INFORMATION', 'URGENT', 'YAMMER']

const NewsScreen = ({ limit, isHomePage = false, navigate }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const news = useReducer(store.store, (s) => s.news)
  const type = useReducer(store.store, (s) => s.type)
  const [tags, setTags] = React.useState<string[]>(['INFORMATION', 'URGENT', 'CAMPUS', 'YAMMER'])
  const navigation = useNavigation()
  const user = useReducer(userStore.store, (s) => s.user)

  const [status, setStatus] = React.useState<'loading' | 'error' | 'ok'>('loading')

  const filteredNews = !isHomePage
    ? news.filter((n) => tags.indexOf(n.tag) >= 0 && n.type === type.toUpperCase())
    : news

  const showedNews = (!isHomePage ? filteredNews : news).filter((r, i) => !limit || i < limit)

  const load = (displayLoading?: boolean) => {
    if (displayLoading) {
      setStatus('loading')
    }
    if (site && user) {
      api
        .all(site.id, user.type)
        .then((articleList) => {
          store.actions.setNews(articleList.news)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  React.useEffect(() => {
    load()
    if (user) {
      analytics.track({ screenName: analyticsKeys.newsList, userType: user.type, currentSite: site, mySites })
    }
  }, [i18n.lang])

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  const displayInfo = () => store.actions.setDisplayedType('INFO')

  const displayEvent = () => store.actions.setDisplayedType('EVENT')

  const renderArticle = (article: NewsSimpleV6, articleIndex: number) => {
    const notLast = showedNews.length !== articleIndex + 1
    const goToDetails = () => navigation.push(`/news/${article.id}`)

    return (
      <div key={article.id}>
        <ArticleContainer tabIndex={0} onKeyDown={(e) => onEnterKey(e, goToDetails)}>
          <PhotoContainer>
            <NewsImage
              onClick={goToDetails}
              imageURL={article.photo?.url}
              animation={false}
              alt={article.photo?.description || ''}
            />
          </PhotoContainer>
          <ArticleInfoContainer onClick={goToDetails}>
            <TagContainer>
              {article.tag === 'YAMMER' && <YammerIcon src={YammerImage} alt="" />}
              <Tag>{i18n.t(`screens.news.tags.${article.tag}`)}</Tag>
            </TagContainer>
            <TitleArticle>{article.title}</TitleArticle>
            {article.catchphrase && (
              <ArticleDescription
                dangerouslySetInnerHTML={{ __html: article.catchphrase + (article.tag === 'YAMMER' ? '...' : '') }}
              />
            )}
            {article.type === 'EVENT' && !!article.startDate && !!article.endDate && (
              <ArticleDate>{showedDate(i18n, new Date(article.startDate), new Date(article.endDate))}</ArticleDate>
            )}
            {article.type === 'INFO' && article.lifecycle !== 'DRAFT' && (
              <ArticleDate>
                {article.lifecycle === 'APPROVED'
                  ? i18n.t('screens.news.scheduledDate', {
                      date: new Date(article.publicationDate),
                    })
                  : utils.capitalize(
                      i18n.t('screens.news.publicationDate', {
                        duration: formatDistanceToNow(new Date(article.publicationDate), {
                          locale: i18n.locale,
                        }),
                      })
                    )}
              </ArticleDate>
            )}
            {(article.lifecycle === 'DRAFT' || article.lifecycle === 'APPROVED') && (
              <TagContainer marginTop={article.lifecycle === 'DRAFT'}>
                <Icons name="pencil" color={Theme.colors.blue} size={25} />
                <StatusText>{i18n.t(`screens.news.${article.lifecycle}`)}</StatusText>
              </TagContainer>
            )}
          </ArticleInfoContainer>
        </ArticleContainer>
        {notLast && <BlueLineBreak role="presentation" />}
      </div>
    )
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error') {
    return (
      <ErrorContainer>
        <NoNewsError>{i18n.t('screens.news.list.error')}</NoNewsError>
        <ErrorButtonContainer>
          <Button label={i18n.t('common.retry')} onClick={() => load(true)} font={Theme.fonts.h3Bold} />
        </ErrorButtonContainer>
      </ErrorContainer>
    )
  }

  if (news.length === 0) {
    return <NoNewsMessage>{i18n.t('screens.news.list.noNews')}</NoNewsMessage>
  }

  return (
    <>
      {!isHomePage && <TitleHelmet title={i18n.t('screens.news.title')} />}
      {!isHomePage && <Breadcrumb screen="news" path="news" navigation={navigation} />}

      <MainContainer>
        <TitleContainer>
          <Title>{i18n.t('screens.news.title')}</Title>
          {!isHomePage && (
            <TypeContainer>
              <TypeSpace>
                <TypeSelector onClick={displayInfo}>
                  <TypeText selected={type.toUpperCase() === 'INFO'}>
                    {i18n.t('screens.news.list.switch.news')}
                  </TypeText>
                </TypeSelector>
              </TypeSpace>
              <Separator>|</Separator>
              <TypeSpace>
                <TypeSelector onClick={displayEvent}>
                  <TypeText selected={type.toUpperCase() === 'EVENT'}>
                    {i18n.t('screens.news.list.switch.events')}
                  </TypeText>
                </TypeSelector>
              </TypeSpace>
            </TypeContainer>
          )}
        </TitleContainer>
        {!isHomePage && (
          <FilterContainer>
            <FilterButton
              color={Theme.colors.blue}
              iconName="options"
              onClick={() => Modal.open(() => ModalContent(ALL_TAGS, tags, setTags))}
              iconSize={28}
              circleSize={56}
              useFilter={!isHomePage}
              ariaLabel={i18n.t('label.ariaLabel.news.filterOptions')}
            />
          </FilterContainer>
        )}
        {filteredNews.length === 0 ? (
          <NoNewsMessage>{i18n.t('screens.news.list.noNews')}</NoNewsMessage>
        ) : (
          <>{showedNews.map((a, i) => renderArticle(a, i))}</>
        )}
        {limit && navigate && (
          <CenterButtonContainer>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.home.more')}
                onClick={navigate}
                font={Theme.fonts.h3Bold}
                ariaLabel={i18n.t('label.ariaLabel.news.seeMore')}
              />
            </ButtonContainer>
          </CenterButtonContainer>
        )}
      </MainContainer>
    </>
  )
}

export default NewsScreen

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 60px;
  margin-left: 5%;
  margin-right: 10%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 2%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 4%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 4%;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin: 0px;
`
const ArticleContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
    align-items: flex-start;
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const PhotoContainer = styled('div')`
  width: 500px;
  height: 310px;
  background-color: ${(props) => props.theme.colors.middleGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    width: 300px;
    height: 186px;
  }
`
const BlueLineBreak = styled.div`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 20px 10px;
  border-radius: 2px;
`
const ArticleInfoContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
  width: calc(100% - 550px);
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
`
const TagContainer = styled('div')<{ marginTop?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.marginTop && 'margin-top: 12px;'}
`
const Tag = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
  margin: 0;
`
const YammerIcon = styled('img')`
  width: 19px;
  height: 19px;
  margin-right: 10px;
  margin-bottom: 2px;
`
const ArticleDescription = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0;
`
const TitleArticle = styled('h3')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  line-height: 25px;
  margin: 15px 0px;
`
const ArticleDate = styled.p`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.turquoise};
  margin: 15px 0px;
`
const NoNewsMessage = styled('p')`
  margin-top: 40px;
  margin-left: 15px;
  ${(props) => props.theme.fonts.body};
`
const TypeSelector = styled('button')`
  padding: 2px;
  text-align: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const TypeText = styled('h2')<{ selected: boolean }>`
  ${(props) => (props.selected ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  font-size: 20px;
  margin: 5px 0px 0px 0px;
  color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.darkGrey)};
  text-decoration: underline;
  padding-top: ${(props) => (props.selected ? '2px' : '0px')};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    font-size: 14px;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 16px;
  margin-top: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-top: 70px;
  }
`
const TypeContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 0px;
  margin-bottom: 4px;
  margin-left: 80px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 20px;
    margin-bottom: 2px;
  }
`
const Separator = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 20px;
  padding-bottom: 4px;
  line-height: 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    font-size: 20px;
  }
`
const TypeSpace = styled('div')`
  display: flex;
  margin: 0px 20px;
  justify-content: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 0px 5px;
  }
`
const NoNewsError = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    text-align: center;
  }
`
const ErrorButtonContainer = styled('div')`
  display: flex;
  width: 300px;
`
const CenterButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 80px;
  }
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 350px;
`
const ErrorContainer = styled('div')`
  padding: 30px 50px;
  min-height: calc(100vh - 176px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 10px;
    min-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

// FILTER

const FilterContainer = styled.div`
  display: flex;
  position: fixed;
  top: 110px;
  right: 8%;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    top: 70px;
  }
`

const StatusText = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0px 5px;
  color: ${(props) => props.theme.colors.middleGrey};
`
