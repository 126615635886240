import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import SwitchFilter from 'components/switch/SwitchFilter'
import Modal from 'components/modal/Modal'

import YammerImage from 'news/img/yammer.png'

const ModalContent = (tagTab: string[], tags: string[], setTags: (t: string[]) => void) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const [selectedTags, setSelectedTags] = React.useState<string[]>(tags)

  const selectTag = (tag: string) =>
    setSelectedTags(selectedTags.indexOf(tag) >= 0 ? selectedTags.filter((t) => t !== tag) : [...selectedTags, tag])

  const renderSelectableTag = (tag: string) => (
    <SwitchFilter
      label={i18n.t(`screens.news.tags.${tag}`)}
      value={selectedTags.indexOf(tag) >= 0}
      setValue={() => selectTag(tag)}
      icon={tag === 'YAMMER' ? YammerImage : undefined}
    />
  )

  return (
    <ContentModal>
      <TitleModal>{i18n.t('screens.news.titleModal')}</TitleModal>
      {tagTab.length >= 0 && <TagsBar>{tagTab.map(renderSelectableTag)}</TagsBar>}
      <ButtonContainer>
        <Button
          label={i18n.t('screens.news.applyFilter')}
          onClick={() => {
            setTags(selectedTags)
            Modal.close()
          }}
          color={Theme.colors.blue}
          textColor={Theme.colors.white}
          font={Theme.fonts.h3Bold}
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button
          label={i18n.t('screens.news.resetFilter')}
          onClick={() => setSelectedTags(tagTab)}
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          shadow
          font={Theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </ContentModal>
  )
}

export default ModalContent

const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
  margin-top: 25px;
`
const TitleModal = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  font-size: 24px;
  line-height: 26px;
  margin-left: 10px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 320px;
  margin: 10px;
`
const TagsBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  justify-content: center;
`
