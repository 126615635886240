import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import useI18n from 'i18n/useI18n'

import Modal from 'components/modal/Modal'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import { TypePlace } from 'parking/utils'
import { TypeRequest, TypeAffection, TypeNumberPeople, TypeRooms } from 'intervention/utils'

declare type OptionType =
  | ReservationTime
  | ReservationType
  | TypePlace
  | TypeRequest
  | TypeAffection
  | TypeNumberPeople
  | TypeRooms

interface Props {
  title: string
  options: OptionType[]
  setChoice: (newReservationTime: OptionType) => void
  typeModal: 'modal' | 'alert'
  type: 'time' | 'type' | 'sharvy' | 'intervention'
  formType?: string
  formName?: string
}

const ReservationTimeModal = ({ title, options, setChoice, typeModal, type, formType, formName }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const chooseOption = (option: OptionType) => {
    setChoice(option)
    if (typeModal === 'alert') {
      Alert.close()
    } else {
      Modal.close()
    }
  }

  return (
    <MainContainer>
      <ModalTitle>{title}</ModalTitle>
      {options.map((option) => {
        const shownValue = i18n.t(
          `screens.${
            type === 'intervention' && !!formType && !!formName
              ? `incident.form.spain.${formType}.${formName}.${option}`
              : type === 'sharvy'
              ? `planning.sharvy.types.${option}`
              : `desk.${type === 'time' ? 'reservationTime' : 'reservationType'}.${option}`
          }`
        )
        return (
          <ButtonContainer key={option}>
            <Button
              label={shownValue}
              onClick={() => chooseOption(option)}
              font={Theme.fonts.h3Bold}
              ariaLabel={i18n.t('label.ariaLabel.desk.chooseOption', { label: title, choice: shownValue })}
            />
          </ButtonContainer>
        )
      })}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 40px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 330px;
  min-height: 50px;
  margin: 5px 0px;
`

// TEXTES

const ModalTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin-top: 0px;
`

export default ReservationTimeModal
