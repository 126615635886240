import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { findLevelWording, getColorFromStatus } from 'intervention/utils'
import Logger from 'utils/Logger'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import api from 'map/mapApi'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import RequestDetail from './RequestDetail'

interface Props {
  intervention: IncidentV6
  isLast: boolean
  refreshIncidents: () => void
  floors: LocationV6[]
}

const UserRequestInfo = ({ intervention, isLast, refreshIncidents, floors }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [room, setRoom] = React.useState<AreaV4>()
  const {
    level: floor,
    category,
    status,
    subcategory,
    uniqueName,
    complaint,
    canPostAComplaint,
    buildingName,
  } = intervention

  const hasMultipleBuildings = React.useMemo(() => Array.from(new Set(floors.map((f) => f.buildingId))).length > 1, [
    floors,
  ])

  React.useEffect(() => {
    if (site && user && uniqueName) {
      api
        .fetchAreaReferentielWithUniqueName(site.id, uniqueName, user.type)
        .then((res) => setRoom(res.areas[0]))
        .catch(Logger.error)
    }
  }, [])

  const onClick = () =>
    Modal.open(() => <RequestDetail intervention={intervention} refreshIncidents={refreshIncidents} floors={floors} />)

  return (
    <MainContainer>
      <InterventionInfoContainer onClick={onClick}>
        <CategoryTitle>{category}</CategoryTitle>
        <RegularText>{subcategory}</RegularText>

        <RoomInfoContainer>
          <IconTextContainer>
            <StatusIndicator statusColor={getColorFromStatus(status)} />
            <StatusText statusColor={getColorFromStatus(status)}>
              {i18n.t([`screens.incident.status.${status}`, 'screens.incident.status.UNKNOWN'])}
            </StatusText>
          </IconTextContainer>

          {!!room && (
            <IconTextContainer>
              <IconContainer>
                <Icons name="pin" size={15} color={Theme.colors.blue} />
              </IconContainer>
              <RegularText>
                {!!room.building && hasMultipleBuildings ? `${room.building} - ` : ''}
                {findLevelWording(room.floor, i18n, room.floorCustomName)}
              </RegularText>
            </IconTextContainer>
          )}

          {!uniqueName && !!floor && (
            <IconTextContainer>
              <IconContainer>
                <Icons name="pin" size={15} color={Theme.colors.blue} />
              </IconContainer>
              <RegularText>
                {!!buildingName && hasMultipleBuildings ? `${buildingName} - ` : ''}
                {findLevelWording(floor, i18n)}
              </RegularText>
            </IconTextContainer>
          )}
          {canPostAComplaint && (
            <IconTextContainer>
              <IconContainer>
                <Icons name="info" size={15} color={Theme.colors.blue} />
              </IconContainer>
              <RegularText color={Theme.colors.blue}>{i18n.t('screens.incident.claim.canMakeAClaim')}</RegularText>
            </IconTextContainer>
          )}
          {complaint && (
            <IconTextContainer>
              <IconContainer>
                <Icons name="info" size={15} color={Theme.colors.blue} />
              </IconContainer>
              <RegularText color={Theme.colors.blue}>{i18n.t('screens.incident.claim.claimMade')}</RegularText>
            </IconTextContainer>
          )}
        </RoomInfoContainer>
      </InterventionInfoContainer>

      {!isLast && <BlueLineBreak />}
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`

const InterventionInfoContainer = styled('button')`
  margin: 15px 0px;
  padding: 5px 10px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
`
const RoomInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
const IconTextContainer = styled('div')`
  display: flex;
  align-items: center;
`
const IconContainer = styled('div')`
  margin-right: 5px;
`

const CategoryTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;
`
const RegularText = styled('p')<{ color?: string }>`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  ${(props) => props.color && `color: ${props.color}`};
`
const StatusText = styled('p')<{ statusColor: keyof Colors }>`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  color: ${(props) => props.theme.colors[props.statusColor]};
`

const StatusIndicator = styled('div')<{ statusColor: keyof Colors }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.theme.colors[props.statusColor]};
  border-radius: 2px;
  margin: 0px 5px;
`
const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
`

export default UserRequestInfo
