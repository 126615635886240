import * as React from 'react'
import styled from 'theme/styled-components'

import useTheme from 'theme/useTheme'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import api from '../api/editoApi'

import Logger from 'utils/Logger'
import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import Carousel from 'components/image/Carousel'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import LabelWithIcon from 'components/label/LabelWithIcon'

import Loader from 'react-loader-spinner'

interface Props {
  id: string
}

type Status = 'loading' | 'error' | 'success'

const EditosDetailScreen = ({ id }: Props) => {
  const [Theme] = useTheme()
  const [edito, setEdito] = React.useState<InformationDetailV6>()
  const [status, setStatus] = React.useState<Status>('loading')
  const [photoURLs, setPhotoURLs] = React.useState<CarouselItem[]>([])

  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const user = useReducer(userStore.store, (s) => s.user)

  const i18n = useI18n()
  const navigation = useNavigation()

  React.useEffect(() => {
    if (site && user) {
      api
        .getEdito(id, site.id, user.type)
        .then((edito) => {
          setPhotoURLs(
            edito.photos
              .sort((a, b) => a.position - b.position)
              .map((p) => ({ url: p.url, key: `KEY_${p.position}`, description: p.description }))
          )
          setEdito(edito)
          setStatus('success')
        })
        .catch((err) => {
          Logger.error(err)

          // cannot get detail, redirect to home
          navigation.reset()
        })

      api.getEdito(id, site.id, user.type, 'en').then((edito) => {
        analytics.track({
          screenName: analyticsKeys.practicalInfoDetails,
          userType: user.type,
          currentSite: site,
          mySites,
          label: edito.title,
        })
      })
    }
  }, [i18n.lang])

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  const openPDF = (pdf: InformationPDFV6) => {
    if (!!pdf.associatedButtonName && !!pdf.url) {
      if (pdf.url.endsWith('.pdf')) {
        navigation.push('/pdf', {
          pdfUrl: pdf.url,
          pdfTitle: pdf.associatedButtonName,
        })
      } else {
        window.open(`/${i18n.lang}/${pdf.url}`)
      }
    }
    return null
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error') {
    return <Error>{i18n.t('errors.notConnected')}</Error>
  }

  return (
    <>
      <TitleHelmet title={edito?.title || i18n.t('screens.practical_information.title')} />
      <MainContainer>
        {edito ? (
          <div>
            <Breadcrumb
              screen="practical_information"
              details={edito.title}
              path="information"
              navigation={navigation}
            />
            <CenteredView>
              <ArticleTitle>{edito.title}</ArticleTitle>
              {photoURLs.length > 0 && (
                <CarouselContainer>
                  <Carousel items={photoURLs} height={365} />
                </CarouselContainer>
              )}
              {!!edito.pdfs?.length && (
                <InfoBar>
                  {edito.pdfs
                    .filter((p) => !!p.associatedButtonName && !!p.url)
                    .map((p) => (
                      <BottomLabelContainer
                        key={p.url}
                        onKeyDown={(e) => onEnterKey(e, () => openPDF(p))}
                        aria-label={p.associatedButtonName}>
                        <LabelWithIcon
                          text={p.associatedButtonName as string}
                          onClick={() => openPDF(p)}
                          iconName="info"
                          colorIcon={Theme.colors.blue}
                          colorText={Theme.colors.iconGrey}
                        />
                      </BottomLabelContainer>
                    ))}
                </InfoBar>
              )}
              <Content
                dangerouslySetInnerHTML={{ __html: edito.description }}
                onClick={(evt) => {
                  const target = evt.target as HTMLAnchorElement
                  const url = target.href
                  if (!!url) {
                    externalLinkTracking(url, analyticsKeys.practicalInfoDetails, user, site, mySites)
                  }
                }}
              />
            </CenteredView>
          </div>
        ) : (
          <CenteredView>{i18n.t('screens.edito.noInfo')}</CenteredView>
        )}
      </MainContainer>
    </>
  )
}

export default EditosDetailScreen

const MainContainer = styled('div')`
  margin-right: 4%;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 20px;
  }
`
const CenteredView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4%;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.middleGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 10px;
  }
`
const ArticleTitle = styled('h1')`
  ${(props) => props.theme.fonts.contentTitle};
  color: ${(props) => props.theme.colors.blue};
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 34px;
`
const Error = styled.h3`
  ${(props) => props.theme.fonts.body};
  margin-left: 20px;
`
const Content = styled.div`
  ${(props) => props.theme.fonts.body};
  margin-top: 30px;
  text-align: justify;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 80px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 20px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const CarouselContainer = styled('div')``

const InfoBar = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 20px;
`

const BottomLabelContainer = styled('button')`
  padding-right: 5px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
