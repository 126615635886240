import * as React from 'react'
import styled from 'styled-components'

import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  placeHolderURL?: string
  onClick?: () => void
  imageURL?: string
  focus?: boolean
  height?: number
  width?: number
  animation?: boolean
  alt?: string
}

const NewsImage = ({
  placeHolderURL = '',
  onClick,
  imageURL,
  focus = true,
  height = 310,
  width = 500,
  animation = true,
  alt,
}: Props) => {
  const [imageError, setImageError] = React.useState(false)

  const source = React.useMemo(() => (imageError || !imageURL ? placeHolderURL : imageURL), [imageURL, imageError])

  return (
    <Photo
      alt={alt || ''}
      onError={() => setImageError(true)}
      src={source}
      onClick={onClick}
      onKeyPress={(evt) => {
        if ((evt.key === 'Enter' || evt.key === ' ') && !!onClick) {
          onClick()
        }
      }}
      focus={focus}
      height={height}
      width={width}
      animation={animation}
      aria-hidden={!alt}
    />
  )
}

const Photo = styled('img')<{ height: number; width: number; focus: boolean; animation: boolean }>`
  ${(props) => props.focus && 'cursor: pointer;'}
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  object-fit: cover;
  user-select: none;

  ${(props) =>
    props.animation &&
    `
  opacity: ${props.focus ? 1 : 0.5};
  transform: scale(${props.focus ? 1 : 0.9})};
  transition: transform 330ms ease-in-out;`}
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 500px;
    height: 310px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    width: 300px;
    height: 186px;
  }
`

export default NewsImage
