import React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import UserImage from 'authent365/utils/UserPicture'
import Button from 'components/button/Button'
import SiteImage from 'components/button/SiteImage'
import Modal from 'components/modal/Modal'
import PasswordModalContent from './PasswordModalContent'
import LogoutModalContent from 'components/modal/ValidationContent'
import TitleHelmet from 'components/title/TitleHelmet'
import ValidationContent from 'components/modal/ValidationContent'
import ToastAlert from 'components/toast/ToastAlert'
import RingRingModalContent from './RingRingModalContent'
import PreferencesContent from './PreferencesContent'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'

import { logout } from 'authent365/utils/utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'

import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as planningStore from 'planning/planningStore'
import * as featureStore from 'sites/store/featureStore'
import useReducer from 'store/useReducer'

import sitesApi from 'sites/api/sitesApi'
import accountApi from './accountApi'
import planningApi from 'planning/api'

import Loader from 'react-loader-spinner'
import Switch from 'react-switch'
import { refreshSSOToken } from 'planning/ssoToken'

type Status = 'loading' | 'success' | 'error' | 'notStarted'

const myPortalLink =
  'https://engiegbs.service-now.com/myportal?id=sc_category_multi&catalog_id=e009c323ff031100a0a5ffffffffff37&sys_id=456490b4dbcf9380c8b398f3db961942&view=card'

const AccountScreen = () => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const [status, setStatus] = React.useState<Status>('loading')
  const [personnalSites, setPersonnalSites] = React.useState<SiteLightV6[]>([])

  const [disableZapFloorOptions, setDisableZapFloorOptions] = React.useState(false)
  const [zapFloorTopics, setZapFloorTopics] = React.useState<ZapfloorTopic>()

  const [loaderRemindBook, setLoaderRemindBook] = React.useState(false)
  const [loaderWaitingList, setLoaderWaitingList] = React.useState(false)
  const [loaderWeekRecap, setLoaderWeekRecap] = React.useState(false)

  const user = useReducer(userStore.store, (s) => s.user)
  const badges = useReducer(userStore.store, (s) => s.badges)
  const features = useReducer(featureStore.store, (s) => s.features)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const allSites = useReducer(siteStore.store, (s) => s.allSites)
  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)

  const hasRingRing = React.useMemo(
    () =>
      !!site && !!site.features.find((f) => f.type === 'RING_RING') && !!features.find((f) => f.type === 'RING_RING'),
    [site, features]
  )

  const logoutUser = () => {
    if (user) {
      logout(user.type)
      Modal.close()
      navigation.push('/')
    }
  }

  const shouldDisplayZapfloorNotifications = React.useMemo(
    () => !!site && !!site.features.find((f) => f.type === 'SITE') && !!site.locationId && !!userZapfloorId,
    [site, userZapfloorId]
  )

  const canShareReservations = React.useMemo(() => !!site && site.canShareReservations, [site])

  React.useEffect(() => {
    if (!!user && !!site) {
      refreshSSOToken(user.type, site.id, 'ZAPFLOOR').catch((err) =>
        Logger.error('Error while refreshing SSO token : ', err)
      )
    }
  }, [user, userZapfloorId])

  React.useEffect(() => {
    if (user) {
      if (user.type === 'EXTERNAL') {
        sitesApi
          .getAllSitesLight(user.type)
          .then((siteList) => {
            sitesApi
              .getUserSites(user.type)
              .then((personalSites) => {
                const personnalSitesId = personalSites.sites.map((s) => s.id)
                setPersonnalSites(siteList.sites.filter((site) => personnalSitesId.indexOf(site.id) > -1))
              })
              .then(() => setStatus('success'))
              .catch((err) => {
                throw new Error(err)
              })
          })
          .catch((err) => {
            Logger.error(err)
            setStatus('error')
          })
      } else {
        accountApi
          .getBadges(user.type)
          .then((result) => {
            userStore.actions.setBadges(result.badges)
          })
          .then(() => setStatus('success'))
          .catch((err) => {
            Logger.error(err)
            setStatus('error')
          })

        // NOTIFICATION ZAPFLOOR
        refreshZapFloorTopics()
      }

      analytics.track({ screenName: analyticsKeys.account, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  const refreshZapFloorTopics = () => {
    if (!!shouldDisplayZapfloorNotifications && !!user && !!site) {
      planningApi.zapfloor.getNotificationsInfos(user.type, site.id).then(updateTopics)
    }
  }

  const removeAccount = () => {
    if (!!user) {
      setStatus('loading')
      accountApi
        .removeAccount(user?.type)
        .then(() => {
          logout(user.type)
          navigation.push('/login')
        })
        .catch(() => {
          ToastAlert.open(() => (
            <ValidationContent
              title={i18n.t('screens.account.notifications.error')}
              onConfirm={ToastAlert.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        })
        .finally(() => setStatus('success'))
    }
  }

  const askAccountRemoval = () => {
    if (user && user.email) {
      analytics.track({
        screenName: analyticsKeys.removeAccount,
        action: analyticsActionKeys.askingRemovingAccount,
        userType: user.type,
        currentSite: site,
        mySites,
      })
      ToastAlert.open(() => (
        <ValidationContent
          title={i18n.t('screens.account.externalRemoval.removalWarning')}
          cancelButton
          cancelButtonTitle={i18n.t('common.cancel')}
          confirmButtonTitle={i18n.t('common.confirm')}
          onConfirm={() => {
            analytics.track({
              screenName: analyticsKeys.removeAccount,
              action: analyticsActionKeys.confirmRemovingAccount,
              userType: user.type,
              currentSite: site,
              mySites,
            })
            ToastAlert.close()
            removeAccount()
          }}
          onCancel={ToastAlert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ))
    }
  }

  const updateTopics = (res: ZapfloorNotifResponse) => {
    const remindBook = res.data.find((t) => t.attributes.key === 'reservations.hotdesk.reminder_1')
    const waitingListConfirmation = res.data.find(
      (t) => t.attributes.key === 'reservations.hotdesk.approve_reservation_from_waiting_list'
    )
    const weekRecap = res.data.find(
      (t) => t.attributes.key === 'reservations.hotdesk.weekly_overview_upcoming_bookings'
    )

    const newTopics: ZapfloorTopic = {
      remindBook: !!remindBook
        ? {
            id: remindBook.id,
            email: remindBook.attributes.mail_subscribed,
            push: remindBook.attributes.push_notification_subscribed,
          }
        : undefined,
      waitingListConfirmation: !!waitingListConfirmation
        ? {
            id: waitingListConfirmation.id,
            email: waitingListConfirmation.attributes.mail_subscribed,
            push: waitingListConfirmation.attributes.push_notification_subscribed,
          }
        : undefined,
      weekRecap: !!weekRecap
        ? {
            id: weekRecap.id,
            email: weekRecap.attributes.mail_subscribed,
            push: weekRecap.attributes.push_notification_subscribed,
          }
        : undefined,
    }

    setLoaderRemindBook(false)
    setLoaderWaitingList(false)
    setLoaderWeekRecap(false)

    setDisableZapFloorOptions(false)
    setZapFloorTopics(newTopics)
  }

  const toggleZapFloorTopic = (typeId: string, mail_subscribed: boolean, push_notification_subscribed?: boolean) => {
    if (!!user && push_notification_subscribed !== undefined && !!site) {
      setDisableZapFloorOptions(true)
      const body = {
        type: {
          push_notification_subscribed,
          mail_subscribed,
        },
      }
      planningApi.zapfloor
        .updateNotification(user.type, site.id, typeId, body)
        .then(refreshZapFloorTopics)
        .catch(() => {
          setLoaderRemindBook(false)
          setLoaderWaitingList(false)
          setLoaderWeekRecap(false)
          setDisableZapFloorOptions(false)

          ToastAlert.open(() => (
            <ValidationContent
              title={i18n.t('screens.account.notifications.error')}
              onConfirm={ToastAlert.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        })
    }
  }

  const renderPreferences = () => (
    <SitesContainer>
      <Title>{i18n.t('components.preferences.title')}</Title>
      <PreferencesContent />
    </SitesContainer>
  )

  const renderSitesContainer = () => (
    <SitesContainer>
      <Title>{i18n.t(`screens.sites.${personnalSites.length === 1 ? 'mySingleSite' : 'mySites'}`)}</Title>
      <SiteIconsContainer>
        {personnalSites.map((s: SiteLightV6) => (
          <SiteImageContainer key={s.id}>
            <SiteImage imageURL={s.image || ''} circleSize={64} label={s.name} selected={site?.id === s.id} />
          </SiteImageContainer>
        ))}
      </SiteIconsContainer>
    </SitesContainer>
  )

  const renderBadgeContainer = () => (
    <SitesContainer>
      <Title>{i18n.t(`screens.account.myBadges`)}</Title>
      <BadgesContainer>
        {badges.map((b) => {
          const badgeSitesId = [b.siteId, ...(b.accessAllowedSitesIds || [])]
          const badgeSites = allSites.filter((s) => badgeSitesId.includes(s.id))
          const isTagCorrect = badgeSitesId.includes('2') && !!b.tag // Pour le moment, seul le tag sur T1 est correct selon MyPortal
          return badgeSites.map((s) => (
            <BadgeContainer key={s.id}>
              <BadgeName>{s.name}</BadgeName>
              {isTagCorrect && <BadgeTag>{'#' + b.tag}</BadgeTag>}
            </BadgeContainer>
          ))
        })}
      </BadgesContainer>
    </SitesContainer>
  )

  const renderTagsNotifications = () => {
    if (!zapFloorTopics) {
      return null
    }
    return (
      <SitesContainer>
        <Title>{i18n.t(`screens.account.notifications.title`)}</Title>
        <ContentText>{i18n.t('screens.account.notifications.description')}</ContentText>
        <SubTitle>{i18n.t('screens.account.notifications.subtitle')}</SubTitle>
        {!!zapFloorTopics.remindBook && (
          <TopicContainer>
            <Label>{i18n.t('screens.account.notifications.bookings')}</Label>
            <RowContainer>
              <SwitchLabel htmlFor="ZapfloorBookingEmailNotification">
                {i18n.t('screens.account.notifications.email')}
              </SwitchLabel>
              <SwitchContainer>
                {!!loaderRemindBook ? (
                  <SmallLoaderContainer>
                    <Loader type="TailSpin" color={Theme.colors.blue} height={20} width={20} />
                  </SmallLoaderContainer>
                ) : (
                  <Switch
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        if (!!zapFloorTopics.remindBook) {
                          toggleZapFloorTopic(
                            zapFloorTopics.remindBook.id,
                            !zapFloorTopics.remindBook.email,
                            zapFloorTopics.remindBook.push
                          )
                          setLoaderRemindBook(true)
                        }
                        e.preventDefault()
                      }
                    }}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={16}
                    onColor={Theme.colors.blue}
                    offColor={Theme.colors.middleGrey}
                    checked={zapFloorTopics.remindBook.email}
                    onChange={(val: boolean) => {
                      if (!!zapFloorTopics.remindBook) {
                        toggleZapFloorTopic(zapFloorTopics.remindBook.id, val, zapFloorTopics.remindBook.push)
                        setLoaderRemindBook(true)
                      }
                    }}
                    height={20}
                    width={39}
                    disabled={disableZapFloorOptions}
                    aria-checked={zapFloorTopics.remindBook.email}
                    id="ZapfloorBookingEmailNotification"
                  />
                )}
              </SwitchContainer>
            </RowContainer>
          </TopicContainer>
        )}
        {!!zapFloorTopics.waitingListConfirmation && (
          <TopicContainer>
            <Label>{i18n.t('screens.account.notifications.waitingList')}</Label>
            <RowContainer>
              <SwitchLabel htmlFor="ZapfloorWaitingListNotification">
                {i18n.t('screens.account.notifications.email')}
              </SwitchLabel>
              <SwitchContainer>
                {!!loaderWaitingList ? (
                  <SmallLoaderContainer>
                    <Loader type="TailSpin" color={Theme.colors.blue} height={20} width={20} />
                  </SmallLoaderContainer>
                ) : (
                  <Switch
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        if (!!zapFloorTopics.waitingListConfirmation) {
                          toggleZapFloorTopic(
                            zapFloorTopics.waitingListConfirmation.id,
                            !zapFloorTopics.waitingListConfirmation.email,
                            zapFloorTopics.waitingListConfirmation.push
                          )
                          setLoaderRemindBook(true)
                        }
                        e.preventDefault()
                      }
                    }}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={16}
                    onColor={Theme.colors.blue}
                    offColor={Theme.colors.middleGrey}
                    checked={zapFloorTopics.waitingListConfirmation.email}
                    onChange={(val: boolean) => {
                      if (!!zapFloorTopics.waitingListConfirmation) {
                        toggleZapFloorTopic(
                          zapFloorTopics.waitingListConfirmation.id,
                          val,
                          zapFloorTopics.waitingListConfirmation.push
                        )
                        setLoaderWaitingList(true)
                      }
                    }}
                    height={20}
                    width={39}
                    disabled={!zapFloorTopics.waitingListConfirmation.push || disableZapFloorOptions}
                    aria-checked={zapFloorTopics.waitingListConfirmation.email}
                    id="ZapfloorWaitingListNotification"
                  />
                )}
              </SwitchContainer>
            </RowContainer>
          </TopicContainer>
        )}

        {!!zapFloorTopics.weekRecap && (
          <TopicContainer>
            <Label>{i18n.t('screens.account.notifications.week')}</Label>

            <RowContainer>
              <SwitchLabel htmlFor="ZapfloorWeekReminder">{i18n.t('screens.account.notifications.email')}</SwitchLabel>
              <SwitchContainer>
                {!!loaderWeekRecap ? (
                  <SmallLoaderContainer>
                    <Loader type="TailSpin" color={Theme.colors.blue} height={20} width={20} />
                  </SmallLoaderContainer>
                ) : (
                  <Switch
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        if (!!zapFloorTopics.weekRecap) {
                          toggleZapFloorTopic(
                            zapFloorTopics.weekRecap.id,
                            !zapFloorTopics.weekRecap.email,
                            zapFloorTopics.weekRecap.push
                          )
                          setLoaderRemindBook(true)
                        }
                        e.preventDefault()
                      }
                    }}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={16}
                    onColor={Theme.colors.blue}
                    offColor={Theme.colors.middleGrey}
                    checked={zapFloorTopics.weekRecap.email}
                    onChange={(val: boolean) => {
                      if (!!zapFloorTopics.weekRecap) {
                        toggleZapFloorTopic(zapFloorTopics.weekRecap.id, val, zapFloorTopics.weekRecap.push)
                      }
                      setLoaderWeekRecap(true)
                    }}
                    height={20}
                    width={39}
                    disabled={disableZapFloorOptions}
                    aria-checked={zapFloorTopics.weekRecap.email}
                    id="ZapfloorWeekReminder"
                  />
                )}
              </SwitchContainer>
            </RowContainer>
          </TopicContainer>
        )}
      </SitesContainer>
    )
  }

  const badgesOrPassword = () => {
    if (user && user.type !== 'EXTERNAL') {
      window.open(myPortalLink)
    } else {
      Modal.open(PasswordModalContent)
    }
  }

  if (status === 'loading' || !user) {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (!site) {
    navigation.push('/sites')
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.account.title')} />
      <MainContainer>
        <TopContainer>
          <Infos>
            <UserImage
              userId={user.id}
              userFirstName={user.firstName}
              userLastName={user.lastName}
              userDisplayName={user.displayName}
              size="large"
              color={Theme.colors.blue}
            />
            <Name>
              {user.firstName || user.lastName ? `${user.firstName || ''} ${user.lastName || ''}` : user.displayName}
            </Name>
            {user.email && user.type === 'EXTERNAL' && <Email>{user.email}</Email>}
            {hasRingRing && (
              <Button
                label={i18n.t(`screens.ringRing.title`)}
                onClick={() => Modal.open(() => <RingRingModalContent />)}
                font={Theme.fonts.label}
                verticalPadding={10}
                horizontalPadding={15}
                color={Theme.colors.white}
                textColor={Theme.colors.blue}
                shadow
              />
            )}

            {user.type === 'EXTERNAL' && user.email && (
              <RemoveAccountContainer>
                <Button
                  label={i18n.t('screens.account.externalRemoval.askAccountRemoval')}
                  onClick={askAccountRemoval}
                  font={Theme.fonts.label}
                  verticalPadding={10}
                  horizontalPadding={15}
                  color={Theme.colors.white}
                  textColor={Theme.colors.blue}
                  shadow
                />
              </RemoveAccountContainer>
            )}
          </Infos>
        </TopContainer>

        <BottomContainer>
          {user.type === 'EXTERNAL' && renderSitesContainer()}
          <GridContainer>
            {badges.length > 0 && renderBadgeContainer()}
            {!!shouldDisplayZapfloorNotifications && renderTagsNotifications()}
            {!!shouldDisplayZapfloorNotifications && canShareReservations && renderPreferences()}
          </GridContainer>

          <Buttons>
            {personnalSites.length !== 1 && user.type === 'EXTERNAL' && (
              <ButtonContainer>
                <Button
                  label={i18n.t('screens.sites.title')}
                  onClick={() => navigation.push('/sites')}
                  font={Theme.fonts.h3Bold}
                  color={Theme.colors.white}
                  textColor={Theme.colors.blue}
                  shadow
                />
              </ButtonContainer>
            )}
            {(site?.canManageBadges || user.type === 'EXTERNAL') && (
              <ButtonContainer>
                <Button
                  label={i18n.t(`screens.account.${user.type !== 'EXTERNAL' ? 'manageBadges' : 'changePassword'}`)}
                  onClick={badgesOrPassword}
                  font={Theme.fonts.h3Bold}
                  color={Theme.colors.white}
                  textColor={Theme.colors.blue}
                  shadow
                />
              </ButtonContainer>
            )}
            <ButtonContainer>
              <Button
                label={i18n.t('common.logout')}
                onClick={() =>
                  Modal.open(() => (
                    <LogoutModalContent
                      title={i18n.t('screens.account.confirmLogout')}
                      onConfirm={logoutUser}
                      cancelButton
                      confirmButtonTitle={i18n.t('common.yes')}
                      ariaLabelConfirm={i18n.t('label.ariaLabel.account.confirmLogout')}
                      ariaLabelCancel={i18n.t('label.ariaLabel.windowCrossIcon')}
                    />
                  ))
                }
                font={Theme.fonts.h3Bold}
              />
            </ButtonContainer>
          </Buttons>
        </BottomContainer>
      </MainContainer>
    </>
  )
}

export default AccountScreen

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    min-width: 100%;
  }
`
const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 270px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
`
const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 30px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const Infos = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const Buttons = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`
const ButtonContainer = styled('div')`
  display: flex;
  margin: 10px;
  width: 330px;
`
const SitesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  margin: 10px 20px;
  padding: 25px 40px;
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.shadow};
  min-width: 350px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: unset;
  }
`

const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-left: -5px;
  gap: 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    display: flex;
    flex-direction: column;
  }
`

const SiteIconsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 270px;
  margin: 30px 0px 40px 25px;
`
const SiteImageContainer = styled('div')`
  margin: 0px 15px;
`

const BadgesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const SwitchContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`
const TopicContainer = styled('div')`
  margin: 6px 0px 6px 20px;
`
const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const SmallLoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

// TEXTES

const Name = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
`
const Email = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  margin: 0px;
`
const Title = styled('h2')`
  ${(props) => props.theme.fonts.title};
  color: ${(props) => props.theme.colors.blue};
`

const ContentText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 5px 0px 20px;
  color: ${(props) => props.theme.colors.darkGrey};
`

const SwitchLabel = styled('label')`
  cursor: pointer;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 5px 0px;
`

const Label = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 5px 0px;
`

const SubTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 5px 0px;
`

const RemoveAccountContainer = styled('div')`
  margin-top: 12px;
`

const BadgeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 5px 0px 15px;
  gap: 5px;
`

const BadgeName = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`

const BadgeTag = styled('label')`
  ${(props) => props.theme.fonts.labelBold};
`
