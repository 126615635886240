import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getMyInterventions: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<IncidentListV6>(userType, `${config.globalAPIVersion}/incidents?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getRequestCategories: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<CategoryListV6>(userType, `${config.globalAPIVersion}/incidents/categories?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getParentCategories: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<ParentCategoryListV6>(
        userType,
        `${config.globalAPIVersion}/incidents/parentCategories?siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  getRoomRequests: (roomId: string, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<IncidentListV6>(userType, `${config.globalAPIVersion}/incidents/${roomId}?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getAllFloorLocations: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<LocationListV6>(userType, `${config.globalAPIVersion}/incidents/locations?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  createIncident: (incidentForm: FormData, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<IncidentCreationResponseV6>(
        userType,
        `${config.globalAPIVersion}/incidents?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data;',
        },
        undefined,
        incidentForm
      )
    ),
  createInterventionClaim: (claimForm: FormData, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<ComplaintV6>(
        userType,
        `${config.globalAPIVersion}/complaints?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        undefined,
        claimForm
      )
    ),
}

export default api
