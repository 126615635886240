import * as React from 'react'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'

import ZoomImage from './ZoomImage'
import NewsImage from 'news/NewsImage'

interface Props {
  items: CarouselItem[]
  height?: number
}

const IMG_WIDTH = 590

const CarouselComponent = ({ items, height = 400 }: Props) => {
  const [Theme] = useTheme()
  const slide = React.useRef<HTMLDivElement | null>(null)

  const i18n = useI18n()

  const [current, setCurrent] = React.useState(0)
  const [innerW, setInnerW] = React.useState(0)

  const zoomOnImage = (focused: boolean, imageURL: string) => {
    if (focused) {
      ZoomImage.open(imageURL)
    }
  }

  const Item = ({ item, focus, height }: { item: CarouselItem; focus: boolean; height: number }) => (
    <div>
      {current > 0 && focus && (
        <ArrowContainer
          current={current}
          height={height}
          visible={current > 0}
          onClick={() => setCurrent(current - 1)}
          left>
          <Icon
            color={Theme.colors.white}
            size={22}
            name="arrow_right"
            ariaLabel={i18n.t('label.svg.carrouselArrowLeft')}
          />
        </ArrowContainer>
      )}
      <NewsImage
        imageURL={item.url}
        height={height}
        width={590}
        focus={focus}
        onClick={() => zoomOnImage(focus, item.url)}
        alt={item.description}
      />
      {current < items.length - 1 && focus && (
        <ArrowContainer
          current={current}
          height={height}
          visible={current < items.length - 1}
          onClick={() => setCurrent(current + 1)}>
          <Icon
            color={Theme.colors.white}
            size={22}
            name="arrow_right"
            ariaLabel={i18n.t('label.svg.carrouselArrowRight')}
          />
        </ArrowContainer>
      )}
    </div>
  )

  const updateInnerW = React.useCallback(() => {
    if (slide.current) {
      setInnerW(slide.current.offsetWidth)
    }
  }, [slide])

  React.useEffect(() => {
    updateInnerW()
    window.addEventListener('resize', updateInnerW)

    return () => window.removeEventListener('resize', updateInnerW)
  }, [slide])

  const onKeyNavigation = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowRight' && current < items.length - 1) {
      setCurrent(current + 1)
    } else if (e.key === 'ArrowLeft' && current > 0) {
      setCurrent(current - 1)
    } else if (e.key === 'Enter' || e.key === ' ') {
      zoomOnImage(true, items[current]?.url || '')
      e.preventDefault()
    }
  }

  return (
    <MainContainer tabIndex={0} onKeyDown={onKeyNavigation}>
      <Slider ref={slide}>
        <Content
          height={height}
          current={current}
          length={items.length}
          width={items.length * IMG_WIDTH * (items.length === 2 ? 1.3 : 1)}
          sliderWidth={innerW}>
          {items.map((item, idx) => (
            <Item key={item.key} item={item} focus={idx === current} height={height} />
          ))}
        </Content>
      </Slider>
    </MainContainer>
  )
}

export default CarouselComponent

const MainContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ArrowContainer = styled.div<{
  current: number
  height: number
  visible: boolean
  left?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  opacity: 0.5;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  top: ${(props) => (props.height - 32) / 2}px;
  left: ${(props) => (props.left ? props.current * IMG_WIDTH - 64 : (props.current + 1) * IMG_WIDTH + 32)}px;
  background-color: ${(props) => props.theme.colors.black};
  ${(props) => props.left && 'transform: rotate(180deg)'};

  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    left: ${(props) => (props.left ? props.current * IMG_WIDTH - 24 : (props.current + 1) * IMG_WIDTH - 2)}px;
  }

  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    opacity: 0.8;
    top: 139px;
    left: ${(props) => (props.left ? props.current * 500 + 5 : (props.current + 1) * 500 - 37)}px;
  }

  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    top: 77px;
    left: ${(props) => (props.left ? props.current * 300 + 5 : (props.current + 1) * 300 - 37)}px;
  }
`

const Slider = styled('div')`
  overflow: hidden;
`

const Content = styled('div')<{ height: number; current: number; width: number; sliderWidth: number; length: number }>`
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  transition: transform 330ms ease-in-out;

  ${(props) =>
    props.length > 1 &&
    `transform: ${`translateX(-${
      props.current !== props.length - 1
        ? props.current === 0
          ? 0
          : props.current * IMG_WIDTH - (props.sliderWidth - IMG_WIDTH) / 2
        : props.width - props.sliderWidth
    }px)`};`}

  ${(props) =>
    props.length === 2 &&
    `@media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: ${props.length * 500}px;

    ${
      props.length > 1 &&
      `transform: ${`translateX(-${
        props.current !== props.length - 1
          ? props.current === 0
            ? 0
            : props.current * 500 - (props.sliderWidth - 500) / 2
          : props.length * 700 - props.sliderWidth
      }px)`};`
    }
  }
  
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    width: ${props.length * 500}px;

    ${
      props.length > 1 &&
      `transform: ${`translateX(-${
        props.current !== props.length - 1
          ? props.current === 0
            ? 0
            : props.current * 500 - (props.sliderWidth - 500) / 2
          : props.length * 600 - props.sliderWidth
      }px)`};`
    }
  }`}

  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    height: 310px;
    width: ${(props) => props.length * 500}px;

    ${(props) =>
      props.length > 1 &&
      `transform: ${`translateX(-${
        props.current !== props.length - 1
          ? props.current === 0
            ? 0
            : props.current * 500 - (props.sliderWidth - 500) / 2
          : props.length * 500 - props.sliderWidth
      }px)`};`}
  }

  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    height: 186px;
    width: ${(props) => props.length * 300}px;

    ${(props) =>
      props.length > 1 &&
      `transform: ${`translateX(-${
        props.current !== props.length - 1
          ? props.current === 0
            ? 0
            : props.current * 300 - (props.sliderWidth - 300) / 2
          : props.length * 300 - props.sliderWidth
      }px)`};`}
  }
`
